<template>
  <v-container fluid class="pt-0">
    <div v-if="id" class="text-h5">编辑库存</div>
    <div v-else class="text-h5">添加库存</div>
    <div class="mt-1 mb-5" style="border-bottom: 1px solid #ddd;"></div>
    <v-row no-gutters class="mt-5">
      <v-col cols="12" sm="6" class="pr-5">
        <v-autocomplete :readonly="!!id" v-model="book" :items="bookStates" @update:search-input="searchBook" outlined label="书籍编号">
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" class="pr-5">
        <v-text-field outlined label="书籍名称" readonly v-model="bookName" class="text-body-2"/>
      </v-col>
      <v-col cols="12" sm="6" class="pr-5">
        <v-text-field outlined label="数量" v-model="inventory_mes.count" class="text-body-2"/>
      </v-col>
      <v-col cols="12" sm="6" class="pr-5">
        <v-text-field outlined label="单价" v-model="inventory_mes.price" class="text-body-2"/>
      </v-col>
      <v-col cols="12" sm="6" class="pr-5">
        <v-text-field outlined label="说明" v-model="inventory_mes.description" class="text-body-2"/>
      </v-col>
      <v-col cols="12" sm="6" class="pr-5">
        <v-btn color="primary" @click="update">提交</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {get_only,update,add} from "../../api/inventory";
import {getBookList} from "../../api/books";
import _ from 'lodash'

export default {
  name: "add-or-edit",
  props: {
    id: [String, Number],
  },
  data(){
    return{
      inventory_mes:{},
      book:'',
      bookStates:[],
      books:[],
      bookName:''
    }
  },
  async created() {
    if (this.id){
      this.inventory_mes=await get_only(this.id)
      console.log(this.inventory_mes)
      console.log(this.inventory_mes)
      this.book=this.inventory_mes.book.book_number

      this.bookName=this.inventory_mes.book.title
      this.bookStates.push(this.inventory_mes.book.book_number)
    }
  },
  methods:{
    searchBook:_.debounce(function (e) {
      if (e&&e!== this.book) {
        getBookList({
          number: e,
          pagesize:1000,
          status:1
        }).then((res) => {
          this.books.length=0
          this.bookStates.length=0
          res.results.forEach(item=>{
            this.inventory_mes.book=Number(item.id)
            this.bookStates.push(item.book_number)
            this.books.push({
              [item.book_number]:item.title
            })
          })
        });
      }
      if (this.books.length){
        const d=this.books.filter(item=>Object.keys(item)[0]===this.book)
        this.bookName=d[0][this.book]
      }
    }),
    async update(){
      console.log(this.inventory_mes)
      if (this.id){
        this.inventory_mes.book=this.inventory_mes.book.id
        await update(this.inventory_mes)
      }else{
        await add(this.inventory_mes)
      }

      this.$snackbar({
        content: this.id ? '修改成功' : '添加成功',
        color: 'success',
        showClose: true,
      })

    }
  }
}
</script>

<style scoped>

</style>